import { PractitionerIdentifierSystemName, PdtType } from '@/apollo/types';

export const enum LoginFields {
  EMAIL = 'email',
  PASSWORD = 'password'
}
export type LoginFormType = {
  email: string,
  password: string,
};

export const enum ResetPasswordFields {
  EMAIL = 'email',
}
export type ResetPasswordFormType = {
  email: string,
};

export const enum GetSparkFields {
  IDENTIFIER_VALUE = 'identifierValue',
  IDENTIFIER_SYSTEM = 'identifierSystem',
  IDENTIFIER_STATE = 'identifierState',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  EMAIL = 'email',
  PASSWORD = 'password',
  PHONE = 'phone',
  PREFERRED_CONTACT_METHOD = 'preferredContactMethod',
  ORGANIZATION_NAME = 'organizationName',
  POSITION = 'position',
  HAS_PREFERRED_CONTACT = 'hasPreferredContact',
  ORGANIZATION_EMAIL = 'organizationEmail',
  ORGANIZATION_PHONE = 'organizationPhone',
  ADDRESS = 'address',
  CITY = 'city',
  STATE = 'state',
  POSTAL_CODE = 'postalCode',
}
export type GetSparkFormType = {
  identifierSystem: PractitionerIdentifierSystemName,
  identifierValue: string,
  identifierState: string,
  firstName: string,
  lastName: string,
  email: string,
  password: string,
  phone: string,
  preferredContactMethod: string,
  organizationName: string,
  position: string,
  hasPreferredContact: string,
  organizationEmail: string,
  organizationPhone: string,
  address: string,
  city: string,
  state: string,
  postalCode: string,
}

export const enum EnrollPatientFields {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  DATE_OF_BIRTH = 'dateOfBirth',
  EMAIL = 'email',
  PHONE = 'phone',
}
export type EnrollPatientFormType = {
  firstName: string,
  lastName: string,
  dateOfBirth: string,
  email: string,
  phone: string,
  pdt: PdtType,
};
