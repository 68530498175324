import React, { useEffect, useState, useCallback } from 'react';
import {
  Step,
  StepLabel,
} from '@material-ui/core';
import { Box, Text } from '@bighealth/react-limbix-ui';
import {
  useForm,
  useFormState,
  FormProvider,
  Control,
} from 'react-hook-form';

import Styled from './GetSpark.styles';
import Steps from './Steps';

import { useModal } from '@/hooks/redux';
import { GetSparkFormType, GetSparkFields, GraphQLErrorsType } from '@/types';
import { capitalizeFirstLetter } from '@/utils/stringUtils';
import { PractitionerIdentifierSystemName } from '@/apollo/types';

type FormKeys = keyof GetSparkFormType;

type FormStateType = {
  dirty: Partial<Record<FormKeys, boolean>>,
  touched: Partial<Record<FormKeys, boolean>>,
  errors: Partial<Record<FormKeys, Record<'type' | 'message', string>>>,
}

const WindowListener = ({ control }: { control: Control<GetSparkFormType> }) => {
  const { dirtyFields, touchedFields, errors: formErrors } = useFormState<GetSparkFormType>({
    control,
  });

  const eventLabel: FormStateType = {
    dirty: dirtyFields,
    touched: touchedFields,
    errors: {},
  };

  Object.entries(formErrors).forEach(([key, value]) => {
    const { type, message } = value;
    eventLabel.errors[key as FormKeys] = {
      type,
      message,
    };
  });

  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      // Cancel the event as stated by the standard.
      e.preventDefault();
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [eventLabel]);
  return <></>;
};

const ERRORS = {
  NPI_ERROR: 'not a valid npi',
  EXISTING_EMAIL_ERROR: 'email already exists',
  INVALID_EMAIL_ERROR: 'enter a valid email',
  PASSWORD_ERROR: 'password',
  PHONE_ERROR: 'phone',
};

const STEP_NAMES = [
  'Account setup',
  'Practice information',
];

type Props = {
  onSubmit: (formData: GetSparkFormType) => Promise<void>;
  loading: boolean;
  errors: GraphQLErrorsType;
};
const GetSpark: React.FC<Props> = (props) => {
  const { onSubmit, loading, errors } = props;
  const { showModal } = useModal();
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [npiFailures, setNpiFailures] = useState(0);
  const [phoneFailures, setPhoneFailures] = useState(0);
  const formMethods = useForm<GetSparkFormType>({
    mode: 'onChange',
    defaultValues: {
      identifierValue: '',
      identifierSystem: PractitionerIdentifierSystemName.Npi,
      identifierState: '',
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      phone: '',
      preferredContactMethod: null as string,
      organizationName: '',
      position: '',
      hasPreferredContact: null as string,
      organizationEmail: '',
      organizationPhone: '',
      address: '',
      city: '',
      state: '',
      postalCode: '',
    },
  });

  const {
    setError,
    setFocus,
    getValues,
    control,
  } = formMethods;

  const handleNpiError = useCallback(() => {
    const npiErrorMessage = npiFailures >= 2
      ? 'Invalid NPI, please contact clinic@bighealth.com'
      : 'Invalid NPI, please try again';
    setError(GetSparkFields.IDENTIFIER_VALUE, { message: npiErrorMessage });
    setNpiFailures((currentNpiFailures) => currentNpiFailures + 1);
    setCurrentStepIndex(0);
    return GetSparkFields.IDENTIFIER_VALUE;
  }, [npiFailures]);

  const handlePhoneError = useCallback(() => {
    let phoneField = GetSparkFields.PHONE;
    if (getValues(GetSparkFields.ORGANIZATION_PHONE) && phoneFailures % 2 === 1) {
      phoneField = GetSparkFields.ORGANIZATION_PHONE;
    }
    setError(phoneField, { message: 'Please enter a valid phone number' });
    setPhoneFailures((currentPhoneFailures) => currentPhoneFailures + 1);
    setCurrentStepIndex(phoneField === GetSparkFields.PHONE ? 0 : 1);
    return GetSparkFields.PHONE;
  }, [phoneFailures]);

  const handleExistingEmailError = useCallback(() => {
    setError(GetSparkFields.EMAIL, { message: 'User with this email already exists' });
    setCurrentStepIndex(0);
    return GetSparkFields.EMAIL;
  }, []);

  const handleValidEmailError = useCallback(() => {
    setError(GetSparkFields.EMAIL, { message: 'Please enter a valid email address' });
    setCurrentStepIndex(0);
    return GetSparkFields.EMAIL;
  }, []);

  const handlePasswordError = useCallback((errorMessage: string) => {
    const errorTexts: string[] = JSON.parse(errorMessage.replace(/'/g, '"'));
    const sentenceCasedTexts = errorTexts.map(capitalizeFirstLetter);
    setError(GetSparkFields.PASSWORD, { message: sentenceCasedTexts.join(' ') });
    setCurrentStepIndex(0);
    return GetSparkFields.PASSWORD;
  }, []);

  const handleUnexpectedError = useCallback(() => {
    showModal('GENERIC_WARNING', {
      header: 'Something went wrong',
      height: '220px',
      width: '350px',
      body: (
        <Text>
          Please contact
          {' '}
          <a href="mailto:spark@bighealth.com">spark@bighealth.com</a>
          {' '}
          for help
        </Text>
      ),
    });
  }, []);

  const handleErrorExists = useCallback((errorMessage: string) => {
    if (errorMessage.includes(ERRORS.NPI_ERROR)) {
      return handleNpiError();
    }
    if (errorMessage.includes(ERRORS.PHONE_ERROR)) {
      return handlePhoneError();
    }
    if (errorMessage.includes(ERRORS.EXISTING_EMAIL_ERROR)) {
      return handleExistingEmailError();
    }
    if (errorMessage.includes(ERRORS.INVALID_EMAIL_ERROR)) {
      return handleValidEmailError();
    }
    if (errorMessage.includes(ERRORS.PASSWORD_ERROR)) {
      return handlePasswordError(errorMessage);
    }
    return handleUnexpectedError();
  }, [
    handleNpiError,
    handlePhoneError,
    handleExistingEmailError,
    handleValidEmailError,
    handlePasswordError,
    handleUnexpectedError,
  ]);

  useEffect(() => {
    const errorMessage = errors?.length && errors[0].message.toLowerCase();
    if (errorMessage?.length) {
      const focusField = handleErrorExists(errorMessage);
      if (focusField) {
        setTimeout(() => {
          setFocus(focusField);
        }, 100);
      }
    }
  }, [errors]);

  const handleClickNextButton = () => {
    setCurrentStepIndex(1);
    window.scrollTo(0, 0);
  };

  const handleClickBackButton = () => {
    setCurrentStepIndex(0);
    window.scrollTo(0, 0);
  };

  return (
    <FormProvider {...formMethods}>
      <Styled.Stepper activeStep={currentStepIndex} alternativeLabel>
        {STEP_NAMES.map((stepName) => (
          <Step key={stepName}>
            <StepLabel>
              {stepName}
            </StepLabel>
          </Step>
        ))}
      </Styled.Stepper>
      <Box as="form" width="100%" onSubmit={formMethods.handleSubmit(onSubmit)}>
        {currentStepIndex === 0 ? (
          <Steps.AccountCreation onClickNextButton={handleClickNextButton} />
        ) : (
          <Steps.PracticeInformation
            loading={loading}
            onClickBackButton={handleClickBackButton}
          />
        )}
      </Box>
      <WindowListener control={control} />
    </FormProvider>
  );
};

export default GetSpark;
