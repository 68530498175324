/* eslint-disable max-len */
export const LANDING_PAGE_ROUTE = '/login';
export const PDT_UID = 'nRDcAS9wCDUpe5ggJ2M7Ya';
export const INVALID_CREDENTIALS = 'Please, enter valid credentials';
export const EMAIL_OR_PASSWORD_INCORRECT = 'Your email and/or password is incorrect. Please try again.';

export const GENDER_MAP = {
  M: 'Male',
  F: 'Female',
  X: 'Non-Binary',
  O: 'Other',
};
export const ADHERENCE_SCORE_DATA = [{
  text: 'N/A',
  bgcolor: '#F3F3F3',
  color: '#9B9CA0',
},
{
  text: 'Low',
  bgcolor: '#FFF3EE',
  color: '#DF5F30',
},
{
  text: 'Medium',
  bgcolor: '#FFF8E5',
  color: '#C69101',
},
{
  text: 'High',
  bgcolor: '#EFFBF8',
  color: '#089A7E',
}];

export const US_STATES = ['Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California',
  'Colorado', 'Connecticut', 'Delaware', 'Florida', 'Georgia',
  'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa',
  'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland',
  'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri',
  'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey',
  'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio',
  'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode Island', 'South Carolina',
  'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont',
  'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'];

export const INCOMPLETE_PHQ_Y_VALUE = -4;
export const HASNT_STARTED_PROGRAM = 'hasn\'t started program';
export const INCOMPLETE_ASSESSMENT_DATA = 'incomplete assessment data';

export const PHQ_STRING_TO_TABLE_VALUE = new Map([
  [HASNT_STARTED_PROGRAM, -1],
  ['-', -1],
  [INCOMPLETE_ASSESSMENT_DATA, -2],
]);

export const DATE_FORMAT_REGEXP = new RegExp(/^\d{2}\/\d{2}\/\d{4}$/);
export const VALID_DATE_REGEXP = new RegExp(/^(0[1-9]|1[012])\/(0[1-9]|[12]\d|3[01])\/(19|20)\d{2}$/);

export const INITIAL_TABLE_PAGE = 0;
export const DEFAULT_PAGINATION_TOTAL = 20;
export const DEFAULT_ITEMS_PER_PAGE = 20;

export const NUDGE_QUERY_ERROR_MESSAGE = 'Hmm...the prewritten nudge failed to load. Please refresh the page or try again later.';
export const FAILED_TO_SEND_NUDGE_ERROR_MESSAGE = 'The ability to send a nudge is unexpectedly down. Please refresh the page. If the problem persists, try again later or reach out to clinic@bighealth.com.';
