import { SnackbarOrigin } from '@material-ui/core';
import { Color } from '@material-ui/lab';

import { IdleTimerPayloadType } from './DataTypes';
import { SortState } from './TableTypes';
import { MutationResultType } from './ApolloTypes';

import { CareTeamType, PdtType, PractitionerAccessCodeType } from '@/apollo/types';

/* Global Action/Reducer Types */
export enum GlobalActionTypes {
  ClearStore = 'CLEAR_STORE',
}

export type GlobalAction = {
  type: GlobalActionTypes;
}

/* Provider User Action/Reducer Types */
export type ProviderUserType = {
  isLoggedIn: boolean;
  hasAccess: boolean;
  isAdmin: boolean;
  isLimbix: boolean;
  AllowedPDTNames: Array<PdtType>;
};

/* Navigation Action/Reducer Types */
export type NavigationType = {
  isBlocked: boolean;
  listeners: Array<keyof WindowEventMap>,
  message?: string;
};

/* Pagination Action/Reducer Types */
export type PaginationType = {
  total: number;
  itemsPerPage: number;
  currentPage: number;
  sortState: SortState;
};

type TableId = string;

export type PaginationStateType = Record<TableId, PaginationType>;

export type SetPaginationType = {
  updateTotal: (total: number) => void,
  nextPage: () => void,
  previousPage: () => void,
  goToPage: (page: number) => void,
  updateSortState: (sortState: SortState) => void,
};

/* Units Action/Reducer Types */
export type TimeUnit = 'Minutes' | 'Hours';

export type UnitsType = {
  time: TimeUnit;
};

export type UseUnitsReturnType = {
  units: UnitsType,
  updateTimeUnit: (timeUnit: TimeUnit) => void,
}

export type ModalPayloadType = {
  NONE: null;
  IDLE_WARNING: IdleTimerPayloadType;
  CONFIRMATION_POPUP: {
    header: string;
    body: string;
    cancelButtonText?: string;
    confirmButtonText?: string;
    onConfirm: () => void;
  };
  CONFIRMATION_POPUP_WITH_MUTATION: {
    header: string;
    body: string;
    cancelButtonText?: string;
    confirmButtonText?: string;
    mutation: () => Promise<MutationResultType>;
  };
  GENERIC_WARNING: {
    header: string;
    body: React.ReactNode;
    height?: string;
    width?: string;
  };
  NUDGE_WARNING: {
    header: string;
    body: string;
    onContinueSendingNudge: () => void;
  }
};

export type ModalContentType = keyof ModalPayloadType;

export type UseModalReturnType = {
  showModal: (modalType: ModalContentType, payload: ModalPayloadType[ModalContentType]) => void,
  hideModal: () => void,
}

export type DrawerPayloadType = {
  NONE: null;
  SEND_NUDGE: {
    patientFirstName: string;
    patientLastName: string;
    providerNotification: string;
    messageBody: string;
    nudgeOpportunityUid: string;
    careteam: CareTeamType;
    onSuccess?: () => void;
    queryErrorExists: boolean;
  }
  ENROLL_PATIENT: PractitionerAccessCodeType;
};

export type DrawerContentType = keyof DrawerPayloadType;

export type UseDrawerReturnType = {
  showDrawer: (drawerType: DrawerContentType, payload: DrawerPayloadType[DrawerContentType]) => void,
  hideDrawer: () => void,
}

export type ToastPayloadType = {
    severity: Color;
    message: string;
    autoHideDurationMs?: number,
    verticalPosition?: SnackbarOrigin['vertical'];
    horizontalPosition?: SnackbarOrigin['horizontal'];
    title?: string;
}

export type UseToastReturnType = {
  showToast: (payload: ToastPayloadType) => void,
  hideToast: () => void,
}
