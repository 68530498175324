import { IconButton } from '@material-ui/core';
import { useSelector } from 'react-redux';

import { Box } from '@bighealth/react-limbix-ui';
import React, { useState } from 'react';

import { RootState } from '@/store';
import { Close } from '@/icons';
import EnrollPatientForm from '@/components/Form/EnrollPatient';
import usePaginatedPatients from '@/hooks/apollo/queries/usePaginatedPatients';
import { EnrollPatientFormType, GraphQLErrorsType } from '@/types';
import { MutationEnrollPatientArgs, PractitionerAccessCodeType } from '@/apollo/types';
import { useMutation } from '@/hooks/apollo';
import { ENROLL_PATIENT } from '@/apollo/mutations';

import { PDT_UID } from '@/utils/constants';

type Props = {
    onClose: () => void;
    payload?: PractitionerAccessCodeType;
  };

const EnrollPatientDrawer: React.FC<Props> = (props: Props) => {
  const {
    onClose,
    payload,
  } = props;
  const allowedPDTNames = useSelector((state: RootState) => state.providerUser.AllowedPDTNames);
  const pdtUids = allowedPDTNames?.map((pdt) => pdt.uid);
  const { refetch } = usePaginatedPatients(pdtUids);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [enrollPatientMutation] = useMutation<MutationEnrollPatientArgs>(
    ENROLL_PATIENT,
  );
  const [errors, setErrors] = useState<GraphQLErrorsType>();
  const pdt = payload?.pdt;
  // Default to Spark direct to avoid breaking changes
  const handleSubmit = async (formData: EnrollPatientFormType) => {
    const {
      email,
      firstName,
      lastName,
      phone,
      dateOfBirth,
    } = formData;
    const { data, errors: resultErrors } = await enrollPatientMutation({
      variables: {
        email,
        firstName,
        lastName,
        phone: phone || null,
        dateOfBirth: new Date(dateOfBirth),
        pdtUid: (pdt?.uid || PDT_UID),
      },
    });

    if (resultErrors?.length) {
      setErrors(resultErrors);
    } else if (data?.enrollPatient?.success) {
      setSubmitSuccess(true);
      refetch();
      onClose();
    }
  };

  return (
    <Box width="min(420px, 100vw)">
      <Box width="48px" height="48px" float="right">
        <IconButton
          aria-label="close drawer"
          onClick={onClose}
        >
          <Close width="24px" height="24px" />
        </IconButton>
      </Box>
      <Box padding="40px">
        <EnrollPatientForm onSubmit={handleSubmit} errors={errors} submitSuccess={submitSuccess} pdt={pdt} />
      </Box>
    </Box>
  );
};

export default EnrollPatientDrawer;
