import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PdtType } from '@/apollo/types';

import {
  ProviderUserType,
} from '@/types/ReduxTypes';

const defaultProviderUserTypeState: ProviderUserType = {
  isLoggedIn: false,
  hasAccess: false,
  isAdmin: false,
  isLimbix: false,
  AllowedPDTNames: Array<PdtType>(),
};

const providerUserSlice = createSlice({
  name: 'providerUser',
  initialState: defaultProviderUserTypeState,
  reducers: {
    updateProviderUser: (_, action: PayloadAction<ProviderUserType>) => ({ ...action.payload }),
  },
});

export const { actions, reducer } = providerUserSlice;
